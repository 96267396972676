$(document).ready(function () {
  // Ініціалізація слайдерів
  $(".slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
  });

  // Приховуємо всі слайдери крім першого
  $("#slider-container-1").addClass("active");

  // Обробник кліку на елемент меню
  $(".navigation li").on("click", function () {
    var sliderId = $(this).data("slider");
    $(".sliders .slider-container")
      .removeClass("active")
      .find(".slider")
      .slick("slickGoTo", 0); // Приховуємо всі слайдери і скидуємо до першого слайду
    $("#slider-container-" + sliderId).addClass("active"); // Показуємо вибраний слайдер з заголовком
  });
});

// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

//Smooth Animation on Scroll
const animItems = document.querySelectorAll("._anim-items");

if (animItems.length > 0) {
  window.addEventListener("scroll", animOnScroll);
  function animOnScroll(params) {
    for (let index = 0; index < animItems.length; index++) {
      const animItem = animItems[index];
      const animItemHeight = animItem.offsetHeight;
      const animItemOffset = offset(animItem).top;
      const animStart = 4;

      let animItemPoint = window.innerHeight - animItemHeight / animStart;
      if (animItemHeight > window.innerHeight) {
        animItemPoint = window.innerHeight - window.innerHeight / animStart;
      }

      if (
        pageYOffset > animItemOffset - animItemPoint &&
        pageYOffset < animItemOffset + animItemHeight
      ) {
        animItem.classList.add("_active");
      } else {
        if (!animItem.classList.contains("_anim-hide")) {
          animItem.classList.remove("_active");
        }
      }
    }
  }
  function offset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + screenLeft };
  }
  animOnScroll();
}

// Check if element entered viewport
function isInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.top < window.innerHeight && rect.bottom >= 0;
}

//Cards
document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".features__item").forEach(function (card) {
    card.addEventListener("click", function () {
      const text = this.querySelector(".features__text");
      if (text.style.maxHeight) {
        text.style.maxHeight = null;
      } else {
        text.style.maxHeight = text.scrollHeight + "px";
      }
    });
  });
});

//Form
$(function () {
  $("form").on("submit", function (e) {
    e.preventDefault();

    const $form = $(this);

    const $popupConfirm = $("#modal1");

    $.ajax({
      url: $form.attr("action"),
      type: $form.attr("method"),
      data: new FormData(this),
      processData: false,
      contentType: false,
    }).done(function () {
      $form.trigger("reset");

      $popupConfirm.addClass("open");

      const $closeBtn = $popupConfirm.find(".popup__close");
      const $overlay = $popupConfirm.find(".popup-overlay");

      $closeBtn.click(function (e) {
        e.preventDefault();
        $popupConfirm.removeClass("open");
        $closeBtn.off("click");
      });
      $overlay.click(function () {
        $popupConfirm.removeClass("open");
        $overlay.off("click");
      });
    });
  });
});

// Burger Menu
function initHamburger() {
  const activeClass = "active";
  const $button = $(".burger__button");
  const $navContainer = $(".header__nav");
  const $navItems = $(".header__link");

  function toggleActiveClass() {
    [$button, $navContainer].forEach((item) => item.toggleClass(activeClass));
  }

  $button.click(toggleActiveClass);
  $navItems.click(toggleActiveClass);
}

$(document).ready(function () {
  initHamburger();
  initAnchorScrolling();
});
